import React, {Component} from 'react'
import styles from './Inquery.module.css'
import Fade from 'react-reveal/Fade'; 
import { StaticQuery, graphql } from "gatsby"


class Inquery extends Component {


  render() {

  

    return (
      <StaticQuery
        query={graphql`
					query InqueryQuery {
						allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "settings-page" } } }) {
							edges {
								node {
									frontmatter {
                    inqueryTitle
                    inqueryDescription
                    inqueryLinkText
                    inqueryLinkUrl
									}
								}
							}
						}
					}
				`}
        render={(data) => {

          const { 
            inqueryTitle,
            inqueryDescription,
            inqueryLinkText,
            inqueryLinkUrl
           } = data.allMarkdownRemark.edges[0].node.frontmatter

           const { isFinance} = this.props

           console.log("isFinance", isFinance);

          return (
            <div className={isFinance ? styles.InqueryFinance : styles.Inquery}>
            <div className="container">
              <div className="row">
                <div className="columns col-xs-12 col-md-9">
                  {inqueryTitle && 
                      <Fade bottom distance={"40px"}>
                        <h1>{inqueryTitle}</h1>
                      </Fade>
                  }
                </div>
    
                <div className="columns col-xs-12 col-md-7 col-md-offset-5">
                  <Fade bottom distance={"40px"}>
                    {inqueryDescription &&
                        <div className="richtext">
                        <p>{inqueryDescription}</p>
                      </div>
                    }
        
                    <a className="link" href={inqueryLinkUrl} target="_blank" rel="noopener noreferrer">
                      <span>{inqueryLinkText}</span>❯❯
                      </a>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
          )
        }}
      />
    )
  }
}

export default Inquery
