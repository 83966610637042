import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import Breadcrumb from "../components/Breadcrumb/Breadcrumb"
import ReactMarkdown from "react-markdown"
import breaks from "remark-breaks"
import MetaTags from "../components/MetaTags"
import Fade from 'react-reveal/Fade'; 
import {StateConsumer} from '../components/StateContext'
import Inquery from "../components/Inquery/Inquery"
import Logo from "../components/Logo/Logo"


export class WordPageTemplate extends Component {
  render() {
    const {data} = this.props
    const {
      title,
      copy,
      finance
    } = data

   
    if(typeof document !== "undefined"){
      if(finance){
          document.body.classList.add('theme-finansk')
      }
      else{
          document.body.classList.remove('theme-finansk')
      }
    }

    if(!data) return null

    return (
      <StateConsumer>{stateContext =>
        <>
      <MetaTags title={data.title}/>

      <section>
        <div className="container">
          <div className="row">
              
              {title &&
                  <div className="columns col-xs-12 col-md-9">
               
                    <Breadcrumb globals={stateContext} />
                    
                    <Fade bottom distance={"40px"}>
                    
                    <h1>{title}</h1>
                    </Fade>
                  </div>
                }

         
                <div className="columns col-xs-12 col-md-7 col-md-offset-5">
                <Fade bottom distance={"40px"} delay={150}>
                  <div className="richtext">
                    {copy &&
                      <ReactMarkdown source={copy} plugins={[breaks]}/>
                    }
                    {!copy &&
                      <p>Beskrivelse mangler</p>
                    }
                  </div>
                  </Fade>
                </div>

          </div>
        </div>
      </section>

      <Inquery isFinance={finance} />
      <Logo />
      </>
      }
      </StateConsumer>
    )
  }
}

WordPageTemplate.propTypes = {
  data: PropTypes.object
}

const WordPage = (props) => {
  const {frontmatter} = props.data.markdownRemark

  return (
    <WordPageTemplate data={frontmatter}/>
  )
}

WordPage.propTypes = {
  data: PropTypes.object
}

export default WordPage

export const pageQuery = graphql`
  query WordPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        templateKey
        title
        copy
        finance
      }
    }
  }
`
